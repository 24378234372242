(function(window, document) {

	if(window.location.pathname !== '/cannabis-packaging-machines/precision-batcher'
	&& window.location.pathname !== '/cannabis-packaging-machines/precision-batcher/'
	&& window.location.pathname !== '/cannabis-packaging-machines/precision-batcher/roi-summary'
	&& window.location.pathname !== '/cannabis-packaging-machines/precision-batcher/roi-summary/') {
		return;
	}

	document.addEventListener('DOMContentLoaded', function(domContentLoadedEvent) {
		const roiInputs = {
			laborCostPerHour: 20,
			pricePerGram: 3,
			lbsPerWeek: 100,
			netWeight: 3.5,
		};

		const roiConstants = {
			handPacking: {
				packagesPerMinute: 1.5,
				packWeightModifier: 1.029,
				productLoss: 0.05,
				initialInvestment: 10000,

				operationalMinutesPerHour: 50,
				cleaningLaborHoursPerDay: 1,
				maintenanceLaborHoursPerDay: 0.25,
			},
			multiHead: {
				packagesPerMinute: 12,
				packWeightModifier: 1.0429,
				productLoss: 0.08,
				initialInvestment: 200000,

				operationalMinutesPerHour: 50,
				cleaningLaborHoursPerDay: 6,
				maintenanceLaborHoursPerDay: 2,
			},
			precisionBatcher: {
				packagesPerMinute: 14,
				packWeightModifier: 1.0115,
				productLoss: 0.01,
				initialInvestment: 169000,

				operationalMinutesPerHour: 50,
				cleaningLaborHoursPerDay: 0.5,
				maintenanceLaborHoursPerDay: 0.5,
			},
		};

		const inputFields = Object.keys(roiInputs).map((input) => {
			return document.getElementById(`roi-input-${input}`);
		}).filter((input) => input != null);

		var inputQueryParams = new URLSearchParams(window.location.search);
		inputFields.forEach((input) => {
			const inputName = input.id.substring('roi-input-'.length);
			if(inputQueryParams.has(inputName)) {
				input.value = parseFloat(inputQueryParams.get(inputName));
			}
			roiInputs[inputName] = input.value;
		});

		function calculateRoiSummary(inputs, constants) {
			const gramsPerPound = 454;

			const handPacking = {};
			const multiHead = {};
			const precisionBatcher = {};

			const averagePackWeight = (netWeight, packWeightModifier) =>
				netWeight * packWeightModifier;

			handPacking.averagePackWeight = averagePackWeight(
				inputs.netWeight,
				constants.handPacking.packWeightModifier
			);
			multiHead.averagePackWeight = averagePackWeight(
				inputs.netWeight,
				constants.multiHead.packWeightModifier
			);
			precisionBatcher.averagePackWeight = averagePackWeight(
				inputs.netWeight,
				constants.precisionBatcher.packWeightModifier
			);

			const lbsPerDay = (packagesPerMinute, operationalMinutesPerHour, averagePackWeight) =>
				packagesPerMinute * operationalMinutesPerHour * 8 * averagePackWeight / gramsPerPound;

			handPacking.lbsPerDay = inputs.lbsPerWeek/5;
			multiHead.lbsPerDay = lbsPerDay(
				constants.multiHead.packagesPerMinute,
				constants.multiHead.operationalMinutesPerHour,
				multiHead.averagePackWeight
			);
			precisionBatcher.lbsPerDay = lbsPerDay(
				constants.precisionBatcher.packagesPerMinute,
				constants.precisionBatcher.operationalMinutesPerHour,
				precisionBatcher.averagePackWeight
			);

			const packagesPerWeek = (lbsPerWeek, averagePackWeight, productLoss) =>
				((lbsPerWeek*gramsPerPound) / averagePackWeight) * (1-productLoss);

			handPacking.packagesPerWeek = packagesPerWeek(
				inputs.lbsPerWeek,
				handPacking.averagePackWeight,
				constants.handPacking.productLoss
			);
			multiHead.packagesPerWeek = packagesPerWeek(
				inputs.lbsPerWeek,
				multiHead.averagePackWeight,
				constants.multiHead.productLoss
			);
			precisionBatcher.packagesPerWeek = packagesPerWeek(
				inputs.lbsPerWeek,
				precisionBatcher.averagePackWeight,
				constants.precisionBatcher.productLoss
			);

			handPacking.operatingDaysPerWeek = 5;
			multiHead.operatingDaysPerWeek = inputs.lbsPerWeek / multiHead.lbsPerDay;
			precisionBatcher.operatingDaysPerWeek = inputs.lbsPerWeek / precisionBatcher.lbsPerDay;

			multiHead.packagingWorkers = 3;
			precisionBatcher.packagingWorkers = 3;

			const packagesPerWorkerPerDay = (packagesPerMinute, operationalMinutesPerHour, packagingWorkers) =>
				packagesPerMinute * operationalMinutesPerHour * 8 / packagingWorkers;

			handPacking.packagesPerWorkerPerDay = packagesPerWorkerPerDay(
				constants.handPacking.packagesPerMinute,
				constants.handPacking.operationalMinutesPerHour,
				1
			);

			multiHead.packagesPerWorkerPerDay = packagesPerWorkerPerDay(
				constants.multiHead.packagesPerMinute,
				constants.multiHead.operationalMinutesPerHour,
				multiHead.packagingWorkers
			);
			precisionBatcher.packagesPerWorkerPerDay = packagesPerWorkerPerDay(
				constants.precisionBatcher.packagesPerMinute,
				constants.precisionBatcher.operationalMinutesPerHour,
				precisionBatcher.packagingWorkers
			);

			handPacking.packagingWorkers = (handPacking.packagesPerWeek/handPacking.operatingDaysPerWeek)/handPacking.packagesPerWorkerPerDay;

			const packingLaborHoursPerWeek = (packagingWorkers, operatingDaysPerWeek) =>
				packagingWorkers * operatingDaysPerWeek * 8;

			handPacking.packingLaborHoursPerWeek = packingLaborHoursPerWeek(
				handPacking.packagingWorkers,
				handPacking.operatingDaysPerWeek
			);
			multiHead.packingLaborHoursPerWeek = packingLaborHoursPerWeek(
				multiHead.packagingWorkers,
				multiHead.operatingDaysPerWeek
			);
			precisionBatcher.packingLaborHoursPerWeek = packingLaborHoursPerWeek(
				precisionBatcher.packagingWorkers,
				precisionBatcher.operatingDaysPerWeek
			);

			handPacking.cleaningLaborHoursPerWeek = constants.handPacking.cleaningLaborHoursPerDay*5;
			multiHead.cleaningLaborHoursPerWeek = constants.multiHead.cleaningLaborHoursPerDay*3;
			precisionBatcher.cleaningLaborHoursPerWeek = constants.precisionBatcher.cleaningLaborHoursPerDay*3;

			handPacking.maintenanceLaborHoursPerWeek = constants.handPacking.maintenanceLaborHoursPerDay*5;
			multiHead.maintenanceLaborHoursPerWeek = constants.multiHead.maintenanceLaborHoursPerDay*3;
			precisionBatcher.maintenanceLaborHoursPerWeek = constants.precisionBatcher.maintenanceLaborHoursPerDay*3;

			const totalLaborHoursPerWeek = (packingLaborHoursPerWeek, cleaningLaborHoursPerWeek, maintenanceLaborHoursPerWeek) =>
				packingLaborHoursPerWeek + cleaningLaborHoursPerWeek + maintenanceLaborHoursPerWeek;

			handPacking.totalLaborHoursPerWeek = totalLaborHoursPerWeek(
				handPacking.packingLaborHoursPerWeek,
				handPacking.cleaningLaborHoursPerWeek,
				handPacking.maintenanceLaborHoursPerWeek
			);
			multiHead.totalLaborHoursPerWeek = totalLaborHoursPerWeek(
				multiHead.packingLaborHoursPerWeek,
				multiHead.cleaningLaborHoursPerWeek,
				multiHead.maintenanceLaborHoursPerWeek
			);
			precisionBatcher.totalLaborHoursPerWeek = totalLaborHoursPerWeek(
				precisionBatcher.packingLaborHoursPerWeek,
				precisionBatcher.cleaningLaborHoursPerWeek,
				precisionBatcher.maintenanceLaborHoursPerWeek
			);

			const laborCostPerWeek = (laborCostPerHour, laborHoursPerWeek) =>
				laborCostPerHour * laborHoursPerWeek;

			handPacking.laborCostPerWeek = laborCostPerWeek(
				inputs.laborCostPerHour,
				handPacking.totalLaborHoursPerWeek
			);
			multiHead.laborCostPerWeek = laborCostPerWeek(
				inputs.laborCostPerHour,
				multiHead.totalLaborHoursPerWeek
			);
			precisionBatcher.laborCostPerWeek = laborCostPerWeek(
				inputs.laborCostPerHour,
				precisionBatcher.totalLaborHoursPerWeek
			);

			const overpackPerPackage = (netWeight, averagePackWeight) =>
				averagePackWeight - netWeight;

			handPacking.overpackPerPackage = overpackPerPackage(
				inputs.netWeight,
				handPacking.averagePackWeight
			);
			multiHead.overpackPerPackage = overpackPerPackage(
				inputs.netWeight,
				multiHead.averagePackWeight
			);
			precisionBatcher.overpackPerPackage = overpackPerPackage(
				inputs.netWeight,
				precisionBatcher.averagePackWeight
			);

			const overpackPercent = (netWeight, overpackPerPackage) =>
				overpackPerPackage/netWeight;

			handPacking.overpackPercent = overpackPercent(
				inputs.netWeight,
				handPacking.overpackPerPackage
			);
			multiHead.overpackPercent = overpackPercent(
				inputs.netWeight,
				multiHead.overpackPerPackage
			);
			precisionBatcher.overpackPercent = overpackPercent(
				inputs.netWeight,
				precisionBatcher.overpackPerPackage
			);

			const overpackPerWeekLbs = (overpackPerPackageGrams, packagesPerWeek) =>
				overpackPerPackageGrams * packagesPerWeek / 454;

			handPacking.overpackPerWeekLbs = overpackPerWeekLbs(
				handPacking.overpackPerPackage,
				handPacking.packagesPerWeek
			);
			multiHead.overpackPerWeekLbs = overpackPerWeekLbs(
				multiHead.overpackPerPackage,
				multiHead.packagesPerWeek
			);
			precisionBatcher.overpackPerWeekLbs = overpackPerWeekLbs(
				precisionBatcher.overpackPerPackage,
				precisionBatcher.packagesPerWeek
			);

			const overpackCostPerWeek = (pricePerGram, overpackPerWeekLbs) =>
				overpackPerWeekLbs * pricePerGram * 454;

			handPacking.overpackCostPerWeek = overpackCostPerWeek(
				inputs.pricePerGram,
				handPacking.overpackPerWeekLbs
			);
			multiHead.overpackCostPerWeek = overpackCostPerWeek(
				inputs.pricePerGram,
				multiHead.overpackPerWeekLbs
			);
			precisionBatcher.overpackCostPerWeek = overpackCostPerWeek(
				inputs.pricePerGram,
				precisionBatcher.overpackPerWeekLbs
			);

			const productLossPerWeekLbs = (lbsPerWeek, productLossPercent) =>
				lbsPerWeek * productLossPercent;

			handPacking.productLossPerWeekLbs = productLossPerWeekLbs(
				inputs.lbsPerWeek,
				constants.handPacking.productLoss
			);
			multiHead.productLossPerWeekLbs = productLossPerWeekLbs(
				inputs.lbsPerWeek,
				constants.multiHead.productLoss
			);
			precisionBatcher.productLossPerWeekLbs = productLossPerWeekLbs(
				inputs.lbsPerWeek,
				constants.precisionBatcher.productLoss
			);

			const productLossCostPerWeek = (pricePerGram, productLossPerWeekLbs) =>
				productLossPerWeekLbs * pricePerGram * 454;

			handPacking.productLossCostPerWeek = productLossCostPerWeek(
				inputs.pricePerGram,
				handPacking.productLossPerWeekLbs
			);
			multiHead.productLossCostPerWeek = productLossCostPerWeek(
				inputs.pricePerGram,
				multiHead.productLossPerWeekLbs
			);
			precisionBatcher.productLossCostPerWeek = productLossCostPerWeek(
				inputs.pricePerGram,
				precisionBatcher.productLossPerWeekLbs
			);

			const yieldLossCostPerWeek = (overpackCostPerWeek, productLossCostPerWeek) =>
				overpackCostPerWeek + productLossCostPerWeek;

			handPacking.yieldLossCostPerWeek = yieldLossCostPerWeek(
				handPacking.overpackCostPerWeek,
				handPacking.productLossCostPerWeek
			);
			multiHead.yieldLossCostPerWeek = yieldLossCostPerWeek(
				multiHead.overpackCostPerWeek,
				multiHead.productLossCostPerWeek
			);
			precisionBatcher.yieldLossCostPerWeek = yieldLossCostPerWeek(
				precisionBatcher.overpackCostPerWeek,
				precisionBatcher.productLossCostPerWeek
			);

			precisionBatcher.laborCostPerYear = precisionBatcher.laborCostPerWeek * 52;
			handPacking.laborCostPerYear = handPacking.laborCostPerWeek * 52;
			multiHead.laborCostPerYear = multiHead.laborCostPerWeek * 52;

			precisionBatcher.yieldLossCostPerYear = precisionBatcher.yieldLossCostPerWeek * 52;
			handPacking.yieldLossCostPerYear = handPacking.yieldLossCostPerWeek * 52;
			multiHead.yieldLossCostPerYear = multiHead.yieldLossCostPerWeek * 52;

			precisionBatcher.firstYearCostTotal = constants.precisionBatcher.initialInvestment + precisionBatcher.laborCostPerYear + precisionBatcher.yieldLossCostPerYear;
			handPacking.firstYearCostTotal = constants.handPacking.initialInvestment + handPacking.laborCostPerYear + handPacking.yieldLossCostPerYear;
			multiHead.firstYearCostTotal = constants.multiHead.initialInvestment + multiHead.laborCostPerYear + multiHead.yieldLossCostPerYear;

			/*
			const annualSavings = (costPerWeek, precisionBatcherCostPerWeek) =>
				(costPerWeek - precisionBatcherCostPerWeek)*52;

			const precisionBatcherSavings = {
				handPacking: {
					labor: annualSavings(
						handPacking.laborCostPerWeek,
						precisionBatcher.laborCostPerWeek),
					yield: annualSavings(
						handPacking.yieldLossCostPerWeek,
						precisionBatcher.yieldLossCostPerWeek)
				},
				multiHead: {
					labor: annualSavings(
						multiHead.laborCostPerWeek,
						precisionBatcher.laborCostPerWeek
					),
					yield: annualSavings(
						multiHead.yieldLossCostPerWeek,
						precisionBatcher.yieldLossCostPerWeek)
				}
			};

			const firstYearSavings = (laborGain, yieldGain, initialInvestment, precisionBatcherInitialInvestment) =>
				(laborGain + yieldGain) - (precisionBatcherInitialInvestment - initialInvestment);

			precisionBatcherSavings.handPacking.firstYearTotal = firstYearSavings(
				precisionBatcherSavings.handPacking.labor,
				precisionBatcherSavings.handPacking.yield,
				constants.handPacking.initialInvestment,
				constants.precisionBatcher.initialInvestment
			);
			precisionBatcherSavings.multiHead.firstYearTotal = firstYearSavings(
				precisionBatcherSavings.multiHead.labor,
				precisionBatcherSavings.multiHead.yield,
				constants.multiHead.initialInvestment,
				constants.precisionBatcher.initialInvestment
			);
			*/

			const precisionBatcherSavings = {
				multiHead: multiHead.firstYearCostTotal - precisionBatcher.firstYearCostTotal,
				handPacking: handPacking.firstYearCostTotal - precisionBatcher.firstYearCostTotal,
			};

			const precisionBatcherWeeklySavingsHandPacking = (handPacking.laborCostPerWeek + handPacking.yieldLossCostPerWeek) - (precisionBatcher.laborCostPerWeek + precisionBatcher.yieldLossCostPerWeek);
			// const returnOnInvestmentWeeks = constants.precisionBatcher.firstYearCostTotal / precisionBatcherSavings
			const precisionBatcherRoiWeeks = constants.precisionBatcher.initialInvestment / (precisionBatcherWeeklySavingsHandPacking);

			return {
				inputs,
				constants,
				intermediateCalculations: {
					handPacking,
					multiHead,
					precisionBatcher
				},
				precisionBatcherSavings,
				precisionBatcherRoiWeeks,
			};
		};

		function roiSummaryToString(roiSummary) {

			const constantsToString = (c) => {
				return {
					packagesPerMinute: c.packagesPerMinute,
					packWeightModifier: c.packWeightModifier,
					productLoss: c.productLoss * 100 + '%',
					initialInvestment: c.initialInvestment.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
					operationalMinutesPerHour: c.operationalMinutesPerHour,
					cleaningLaborHoursPerDay: c.cleaningLaborHoursPerDay,
					maintenanceLaborHoursPerDay: c.maintenanceLaborHoursPerDay,
				};
			};

			const intermediateCalculationsToString = (i) => {
				return {
					averagePackWeight: i.averagePackWeight.toLocaleString(undefined, {maximumFractionDigits: 2}),
					lbsPerDay: i.lbsPerDay.toLocaleString(undefined, {maximumFractionDigits: 0}),
					packagesPerWeek: i.packagesPerWeek.toLocaleString(undefined, {maximumFractionDigits: 0}),
					operatingDaysPerWeek: i.operatingDaysPerWeek.toLocaleString(undefined, {maximumFractionDigits: 1}),
					packagingWorkers: i.packagingWorkers.toLocaleString(undefined, {maximumFractionDigits: 0}),
					packagesPerWorkerPerDay: i.packagesPerWorkerPerDay.toLocaleString(undefined, {maximumFractionDigits: 0}),
					packingLaborHoursPerWeek: i.packingLaborHoursPerWeek.toLocaleString(undefined, {maximumFractionDigits: 1}),
					cleaningLaborHoursPerWeek: i.cleaningLaborHoursPerWeek.toLocaleString(undefined, {maximumFractionDigits: 1}),
					maintenanceLaborHoursPerWeek: i.maintenanceLaborHoursPerWeek.toLocaleString(undefined, {maximumFractionDigits: 1}),
					totalLaborHoursPerWeek: i.totalLaborHoursPerWeek.toLocaleString(undefined, {maximumFractionDigits: 1}),
					laborCostPerWeek: i.laborCostPerWeek.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
					overpackPerPackage: i.overpackPerPackage.toLocaleString(undefined, {maximumFractionDigits: 2}),
					overpackPercent: (i.overpackPercent*100).toLocaleString(undefined, {maximumFractionDigits: 1}) + '%',
					overpackPerWeekLbs: i.overpackPerWeekLbs.toLocaleString(undefined, {maximumFractionDigits: 2}),
					overpackCostPerWeek: i.overpackCostPerWeek.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
					productLossPerWeekLbs: i.productLossPerWeekLbs.toLocaleString(undefined, {maximumFractionDigits: 1}),
					productLossCostPerWeek: i.productLossCostPerWeek.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
					yieldLossCostPerWeek: i.yieldLossCostPerWeek.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),

					laborCostPerYear: i.laborCostPerYear.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
					yieldLossCostPerYear: i.yieldLossCostPerYear.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
					firstYearCostTotal: i.firstYearCostTotal.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
				};
			};

			const precisionBatcherSavingsToString = (s) => {
				return {
					labor: s.labor.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
					yield: s.yield.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
					firstYearTotal: s.firstYearTotal.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
				};
			};

			const output = {
				inputs: {
					laborCostPerHour: `$${roiSummary.inputs.laborCostPerHour}`,
					pricePerGram: `$${roiSummary.inputs.pricePerGram}`,
					lbsPerWeek: roiSummary.inputs.lbsPerWeek,
					netWeight: roiSummary.inputs.netWeight,
				},
				constants: {
					precisionBatcher: constantsToString(roiSummary.constants.precisionBatcher),
					handPacking: constantsToString(roiSummary.constants.handPacking),
					multiHead: constantsToString(roiSummary.constants.multiHead),
				},
				intermediateCalculations: {
					precisionBatcher: intermediateCalculationsToString(roiSummary.intermediateCalculations.precisionBatcher),
					handPacking: intermediateCalculationsToString(roiSummary.intermediateCalculations.handPacking),
					multiHead: intermediateCalculationsToString(roiSummary.intermediateCalculations.multiHead),
				},
				precisionBatcherSavings: {
					handPacking: roiSummary.precisionBatcherSavings.handPacking.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
					multiHead: roiSummary.precisionBatcherSavings.multiHead.toLocaleString(undefined, {maximumFractionDigits: 0, style: 'currency', currency: 'USD', minimumFractionDigits: 0}),
				},
				precisionBatcherRoiWeeks: roiSummary.precisionBatcherRoiWeeks.toLocaleString(undefined, {maximumFractionDigits: 0, minimumFractionDigits: 0})
			};

			return output;
		}

		function updateRoiTableCells(roiSummary) {
			const preparedDateElement = document.getElementById('roi-summary-prepared-date');
			if(preparedDateElement) {
				const preparedDate = new Date();
				preparedDateElement.datetime = preparedDate.toISOString();
				preparedDateElement.textContent = `${preparedDate.getMonth()+1}/${preparedDate.getDate()}/${preparedDate.getFullYear().toString().substring(2)}`;
			}
			Object.keys(roiSummary.inputs).forEach((input) => {
				const element = document.getElementById(`inputs-${input}`);
				if(!element) {
					return;
				}

				element.textContent = roiSummary.inputs[input];
			});

			const roiElement = document.getElementById(`precisionBatcherRoiWeeks`);
			if(roiElement) {
				roiElement.textContent = roiSummary.precisionBatcherRoiWeeks + ' weeks';
			}

			const roiWidgetElement = document.getElementById(`roi-widget-precisionBatcherRoiWeeks`);
			if(roiWidgetElement) {
				roiWidgetElement.textContent = roiSummary.precisionBatcherRoiWeeks;
			}

			Object.keys(roiSummary.constants).forEach((machineType) => {
				Object.keys(roiSummary.constants[machineType]).forEach((calculationType) => {
					const element = document.getElementById(`cell-${machineType}-${calculationType}`);
					if(!element) {
						return;
					}

					element.textContent = roiSummary.constants[machineType][calculationType];
				});
			});
			Object.keys(roiSummary.intermediateCalculations).forEach((machineType) => {
				Object.keys(roiSummary.intermediateCalculations[machineType]).forEach((calculationType) => {
					const element = document.getElementById(`cell-${machineType}-${calculationType}`);
					if(!element) {
						return;
					}

					element.textContent = roiSummary.intermediateCalculations[machineType][calculationType];
				});
			});
			Object.keys(roiSummary.precisionBatcherSavings).forEach((machineType) => {
				const element = document.getElementById(`precisionBatcherSavings-${machineType}`);
				if(!element) {
					return;
				}

				element.textContent = roiSummary.precisionBatcherSavings[machineType];
			});

		}

		function updateRoiWidgetLink(roiSummary) {
			const queryParams = new URLSearchParams({
				laborCostPerHour: roiSummary.inputs.laborCostPerHour,
				pricePerGram: roiSummary.inputs.pricePerGram,
				lbsPerWeek: roiSummary.inputs.lbsPerWeek,
				netWeight: roiSummary.inputs.netWeight,
			});

			const widgetLink = document.getElementById('roi-widget-link');
			if(widgetLink) {
				widgetLink.href = `/cannabis-packaging-machines/precision-batcher/roi-summary?${queryParams.toString()}`
			}

			if(window.location.pathname === '/cannabis-packaging-machines/precision-batcher/roi-summary' || window.location.pathname === '/cannabis-packaging-machines/precision-batcher/roi-summary/' ) {
				const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${queryParams.toString()}`;
				window.history.replaceState({path: newUrl}, '', newUrl);
			}
		}

		function setLoadingIndicator(text, delay) {
			const calculatingElement = document.getElementById(`roi-calculating`);
			if(calculatingElement) {
				if(delay != undefined) {
					setTimeout(() => calculatingElement.textContent = text, delay);
				}
				else {
					calculatingElement.textContent = text;
				}
			}
		}

		function setPrintButtonState(isEnabled) {
			const printButton = document.getElementById(`roi-print-button`);
			if(printButton) {
				printButton.disabled = !isEnabled;
			}
		}

		setLoadingIndicator('Calculating...', 0);
		setPrintButtonState(false);
		const roiSummary = calculateRoiSummary(roiInputs, roiConstants)
		const roiSummaryStr = roiSummaryToString(roiSummary);
		console.log(roiSummary);


		updateRoiTableCells(roiSummaryStr);
		updateRoiWidgetLink(roiSummary);

		setLoadingIndicator('Document ready.', 169);
		setPrintButtonState(true);

		inputFields.forEach((input) => {
			input.addEventListener('change', (event) => {
				roiInputs[input.id.substring('roi-input-'.length)] = input.value;
				setLoadingIndicator('Calculating...');
				setPrintButtonState(false);
				const roiSummary = calculateRoiSummary(roiInputs, roiConstants)
				const roiSummaryStr = roiSummaryToString(roiSummary);
				console.log(roiSummary);
				updateRoiTableCells(roiSummaryStr);
				updateRoiWidgetLink(roiSummary);

				setLoadingIndicator('Document ready.', 169);
				setPrintButtonState(true);
			});
		});
	});
})(window, window.document);
