document.addEventListener('DOMContentLoaded', function(domContentLoadedEvent) {
	(function(window, document) {
		window.addEventListener('load', (event) => {
			document.getElementsByTagName('body')[0].classList.remove('preload-transition');
		});

		const carousels = Array.from(document.getElementsByClassName('carousel'));
		carousels.forEach((carousel) => {
			const carouselNav = carousel.getElementsByClassName('carousel-nav')[0];
			const navElements = Array.from(carouselNav.getElementsByTagName('li'));
			const carouselSlideWrapper = carousel.getElementsByClassName('carousel-slides')[0];

			let currentSlide = 0;
			let skipAutoScroll = 1; // if the user manually changes the selection, skip the next n auto scrolls so it doesn't immediately scroll away.

			const setActiveCarouselSelection = (li) => {
				navElements.forEach((l) => l.classList.remove('active'));
				li.classList.add('active');
			};

			navElements.forEach((li, i) => {
				li.addEventListener('click', (event) => {
					setActiveCarouselSelection(li);
					currentSlide = i;
					skipAutoScroll = 4;

					event.preventDefault();
					carouselSlideWrapper.scroll({
						left: carouselSlideWrapper.clientWidth * currentSlide,
						top: 0,
						behavior: 'smooth'
					});
				});
			});

			// auto-scroll
			const autoScrollDelay = 5000;
			window.setInterval(() => {
				// only auto scroll when the carousel is visible 
				// we don't really care if it's not visible after we've scrolled past it
				const bounding = carouselSlideWrapper.getBoundingClientRect();
				if(bounding.top > (window.innerHeight || document.documentElement.clientHeight)
) {
					return;
				}
				if(skipAutoScroll > 0) {
					skipAutoScroll--;
					return;
				}

				currentSlide = (currentSlide + 1) % navElements.length;
				carouselSlideWrapper.scroll({
					left: carouselSlideWrapper.clientWidth * currentSlide,
					top: 0,
					behavior: 'smooth'
				});
				setActiveCarouselSelection(navElements[currentSlide]);
			}, autoScrollDelay);
		});

		const navbarMobileDropdownButton = document.getElementById('navbarSupportedContentButton');
		const navbarMobileDropdown = document.getElementById('navbarSupportedContent');
		const navbarMobileDropdownWrapper = navbarMobileDropdown.getElementsByClassName('wrapper')[0];

		const isHomepage = document.getElementsByClassName('homepage').length > 0;
		const introSectionWrapper = document.getElementsByClassName('intro-section-wrapper');

		let introSectionWrapperOffset = introSectionWrapper.length > 0? parseInt(window.getComputedStyle(introSectionWrapper[0]).paddingTop): 0;
		window.addEventListener('load', (event) => {
			// update offset after CSS is loaded to get correct padding
			introSectionWrapperOffset = introSectionWrapper.length > 0? parseInt(window.getComputedStyle(introSectionWrapper[0]).paddingTop): 0;

		});

		navbarMobileDropdownButton.addEventListener('click', (event) => {
			if(navbarMobileDropdownButton.dataset.toggle == 'collapse') {
				const borderHeight = 12;
				navbarMobileDropdown.style.height = navbarMobileDropdownWrapper.clientHeight + borderHeight + 'px';
				navbarMobileDropdownButton.dataset.toggle = 'expand';
				if(isHomepage && introSectionWrapper.length > 0) {
					introSectionWrapper[0].style.paddingTop = introSectionWrapperOffset + navbarMobileDropdownWrapper.clientHeight + 'px';
					introSectionWrapper[0].style.marginTop = -(introSectionWrapperOffset + navbarMobileDropdownWrapper.clientHeight) + 'px';
				}
			}
			else {
				navbarMobileDropdown.style.height = 0;
				if(isHomepage && introSectionWrapper.length > 0) {
					introSectionWrapper[0].style.paddingTop = introSectionWrapperOffset + 'px';
					introSectionWrapper[0].style.marginTop = -introSectionWrapperOffset + 'px';
				}
				navbarMobileDropdownButton.dataset.toggle = 'collapse';
			}
		});

		// image gallery
		const imageGallery = document.getElementsByClassName('product-images');
		if(imageGallery.length > 0) {
			const imageGallerySelection = document.getElementsByClassName('image-gallery-selection')[0];
			const imageGallerySelectionImg = imageGallerySelection.getElementsByTagName('picture')[0];
			const imageGallerySelectionVideo = imageGallerySelection.getElementsByTagName('video')[0];
			const imageGallerySelectionPlayButton = imageGallerySelection.getElementsByClassName('play-video-icon')[0];
			const isChromium = navigator.userAgentData && navigator.userAgentData.brands.find((brand) => brand.brand === 'Chromium');
			const isMobile = navigator.userAgentData && navigator.userAgentData.mobile;

			let currentSelection = null;
			let stickySelection = null;
			let lastActiveThumbnail = null;
			// used because setting src can change the value (e.g. add hostname to url)
			let lastVideoSrc = '';

			// hide the play button icon when playback begins
			imageGallerySelectionVideo.addEventListener('play', (event) => {
				imageGallerySelectionPlayButton.style.display = 'none';
			});

			const setImageGallerySelection = (thumbnail) => {
				if(!thumbnail || thumbnail === currentSelection) {
					return;
				}
				
				if(thumbnail.videoSrc) {
					imageGallerySelectionImg.style.display = 'none';

					imageGallerySelectionVideo.style.display = 'block';
					imageGallerySelectionVideo.poster = thumbnail.src[thumbnail.src.length - 1];

					if(imageGallerySelectionVideo.src !== lastVideoSrc) {
						imageGallerySelectionVideo.src = thumbnail.videoSrc;
						lastVideoSrc = imageGallerySelectionVideo.src;
					}

					// show video play button (only on chromium desktop)
					if(isChromium && !isMobile) {
						imageGallerySelectionPlayButton.style.display = 'flex';
					}
					else {
						imageGallerySelectionPlayButton.style.display = 'none';
					}
				}
				else {
					imageGallerySelectionImg.style.display = 'block';
					imageGallerySelectionPlayButton.style.display = 'none';
					// imageGallerySelectionImg.src = thumbnail.src;
					// build the picture element
					imageGallerySelectionImg.innerHTML = '';
					thumbnail.src.forEach((source) => {
						const sourceElement = document.createElement('source');
						sourceElement.srcset = source;
						sourceElement.type = `image/${source.split('.').pop()}`;
						imageGallerySelectionImg.appendChild(sourceElement);
					});

					const imgElement = document.createElement('img');
					imgElement.src = thumbnail.src[thumbnail.src.length - 1];
					imgElement.classList.add('img-fluid');
					if(thumbnail.alt) {
						imgElement.alt = thumbnail.alt;
					}
					imageGallerySelectionImg.appendChild(imgElement);

					imageGallerySelectionVideo.style.display = 'none';
					imageGallerySelectionVideo.pause();
				}

				// set thumbnail highlighting
				if(lastActiveThumbnail) {
					lastActiveThumbnail.classList.remove('active');
				}
				thumbnail.thumbnailElement.classList.add('active');
				lastActiveThumbnail = thumbnail.thumbnailElement;

				currentSelection = thumbnail;
			};

			const imageGalleryThumbnails = Array.from(imageGallery[0].getElementsByClassName('image-gallery-thumbnails')[0].getElementsByClassName('thumbnail')).map((thumbnail) => {
				return {
					thumbnailElement: thumbnail,
					src: Array.from(thumbnail.firstElementChild.getElementsByTagName('source')).map((source) => source.srcset),
					alt: thumbnail.firstElementChild.getElementsByTagName('img')[0].alt,
					videoSrc: thumbnail.firstElementChild.dataset.videosrc,
				};
			});

			if(imageGalleryThumbnails.length > 0) {
				const thumbnail = imageGalleryThumbnails[0]
				stickySelection = thumbnail;
				thumbnail.thumbnailElement.classList.add('selected');
				setImageGallerySelection(thumbnail);

			}

			imageGalleryThumbnails.forEach((thumbnail) => {
				thumbnail.thumbnailElement.addEventListener('mouseenter', (event) => {
				setImageGallerySelection(thumbnail);
				});

				thumbnail.thumbnailElement.addEventListener('click', (event) => {
					if(stickySelection) {
						stickySelection.thumbnailElement.classList.remove('selected');
					}

					stickySelection = thumbnail;
					thumbnail.thumbnailElement.classList.add('selected');
					setImageGallerySelection(thumbnail);
				});

			});

			imageGallery[0].getElementsByClassName('image-gallery-thumbnails')[0].addEventListener('mouseleave', (event) => {
				setImageGallerySelection(stickySelection);
			});
		}


		const companyBiosSection = Array.from(document.getElementsByClassName('company-bios-section'));
		companyBiosSection.forEach((biosSection) => {
			const bioWrapper = Array.from(biosSection.getElementsByClassName('bio-wrapper'));
			const bios = Array.from(biosSection.getElementsByClassName('bio'));
			const portraits = Array.from(biosSection.getElementsByClassName('portrait'));
			portraits.forEach((portrait) => {
				portrait.addEventListener('click', (event) => {
					// const selected = portrait.classList.contains('selected');
					portraits.forEach((p) => {
						p.classList.remove('selected');
					});

					portrait.classList.add('selected');

					// if(!selected) {
						// portrait.classList.add('selected');
					// }
					bios.forEach((bio) => {
						// if(!selected && bio.id === portrait.dataset.bioId) {
						if(bio.id === portrait.dataset.bioId) {
							bio.classList.add('visible');
						}
						else {
							bio.classList.remove('visible');
						}
					});

					bioWrapper.forEach((b) => {
						b.classList.remove('selected-bio-denny');
						b.classList.remove('selected-bio-arnar');
						b.classList.remove('selected-bio-eric');
						b.classList.remove('selected-bio-bibbi');

						b.classList.add(`selected-${portrait.dataset.bioId}`);
					});
				});
			});
		});

		const ourStorySection = Array.from(document.getElementsByClassName('our-story'));
		ourStorySection.forEach((section) => {
			const behindFoldElements = Array.from(section.getElementsByClassName('behind-fold'));
			const buttons = Array.from(section.getElementsByClassName('fold-button'));

			buttons.forEach((button) => {
				button.addEventListener('click', (e) => {
					behindFoldElements.forEach((behindFold) => {
						behindFold.classList.toggle('fold-visible');
					});

					if(button.dataset.toggleText) {
						const text = button.textContent;
						button.textContent = button.dataset.toggleText;
						button.dataset.toggleText = text;
					}
				});
			});
		});
	})(window, window.document)
});
